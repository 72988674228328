import { MarketingOptions } from '_config/enums/marketing-options.enum'

export function getMarketingOptionValue(marketingOptions: { email: boolean; sms: boolean }): number {
    let marketingOptionFlag = MarketingOptions.None
    if (marketingOptions.email) {
        marketingOptionFlag |= MarketingOptions.Email
    }
    if (marketingOptions.sms) {
        marketingOptionFlag |= MarketingOptions.Sms
    }
    return marketingOptionFlag
}

export function revertMarketingOptionValue(value: number, type: MarketingOptions) {
    return !!(value & type)
}

export function cleanAddressesErrors(values: string): string {
    const newValues: AddressInformation[] = JSON.parse(values)
    newValues.map((address: AddressInformation, index: number) => {
        // @ts-ignore
        delete address.error
        newValues[index] = address
    })

    return JSON.stringify(newValues)
}
