import { v4 as uuidv4 } from 'uuid'
import { cleanAddressesErrors, revertMarketingOptionValue } from 'helpers/signup.helper'
import { MarketingOptions } from '_config/enums/marketing-options.enum'
import { getMarketingOptionValue } from 'helpers/signup.helper'

export function buyerProfileMapping(profile: BuyerProfileResponse | BuyerResponse | SignupFormType | null): BuyerProfileViewModel | null {
    if (!profile) return null

    let buyerProfileViewModel: BuyerProfileViewModel = {
        personalInformation: {
            value: {
                firstName: (profile?.firstName as string) ?? '',
                lastName: (profile?.lastName as string) ?? '',
                emailAddress: (profile?.emailAddress as string) ?? '',
                mobileNumber: (profile?.mobileNumber as string) ?? '',
                landLine: (profile?.landLine as string) ?? '',
                address1: (profile?.address1 as string) ?? '',
                address2: (profile?.address2 as string) ?? '',
                city: profile?.city ?? '',
                postCode: profile?.postCode ?? '',
                country: (profile?.country as string) ?? '',
                username: (profile?.username as string) ?? '',
            },
            error: {
                firstName: false,
                lastName: false,
                emailAddress: false,
                mobileNumber: false,
                landLine: false,
                address1: false,
                address2: false,
                city: false,
                postCode: false,
                country: false,
                postCodeLength: false,
            },
        },
        otherInformation: {
            value: {
                naitNumbers: (profile?.naitNumbers as string) ?? '',
                herdCodes: (profile?.herdCodes as string) ?? '',
                participantCodes: (profile?.participantCodes as string) ?? '',
                deliveryAddresses: (profile?.deliveryAddresses as string) ?? '',
                termsAndConditions: profile?.termsAndConditions as boolean,
                marketingOptions: {
                    email: revertMarketingOptionValue(profile?.marketingOptions as number, MarketingOptions.Email),
                    sms: revertMarketingOptionValue(profile?.marketingOptions as number, MarketingOptions.Sms),
                },
            },
            error: {
                naitNumbers: false,
                herdCodes: false,
                participantCodes: false,
                termsAndConditions: false,
                marketingOptions: false,
                addresses: false,
            },
        },
        accountInformation: {
            value: {
                legalEntityName: (profile?.legalEntityName as string) ?? '',
                tradingName: (profile?.tradingName as string) ?? '',
                agencies: profile?.agencies?.map((agency: Maybe<AgencyDetail | BuyerAgencyReponse | AgencyAccount>) => {
                    return {
                        rowId: uuidv4(),
                        id: agency?.id,
                        agencyId: agency?.agencyId?.toString() ?? '',
                        fullAccountNumber: agency?.fullAccountNumber,
                        accountNumber: (agency?.accountNumber as string) ?? '',
                        accountName: (agency?.accountName as string) ?? '',
                        creditStatusDisplayText: agency?.creditStatusDisplayText,
                    } as AgencyAccount
                }) as AgencyAccount[],
            },
            error: {
                legalEntityName: false,
                tradingName: false,
                agencies: false,
            },
        },
    }

    return buyerProfileViewModel
}

export function buyerProfileUpdateMapping(subjectId: string, buyerProfileViewModel: BuyerProfileViewModel): BuyerProfileUpdateMutationVariables {
    return {
        subjectId: subjectId,
        firstName: buyerProfileViewModel?.personalInformation.value.firstName,
        lastName: buyerProfileViewModel.personalInformation.value.lastName,
        emailAddress: buyerProfileViewModel.personalInformation.value.emailAddress,
        mobileNumber: buyerProfileViewModel.personalInformation.value.mobileNumber,
        landLine: buyerProfileViewModel.personalInformation.value.landLine,
        address1: buyerProfileViewModel.personalInformation.value.address1,
        address2: buyerProfileViewModel.personalInformation.value.address2,
        postCode: buyerProfileViewModel.personalInformation.value.postCode,
        city: buyerProfileViewModel.personalInformation.value.city,
        country: buyerProfileViewModel.personalInformation.value.country,
        naitNumbers: buyerProfileViewModel.otherInformation.value.naitNumbers,
        herdCodes: buyerProfileViewModel.otherInformation.value.herdCodes,
        participantCodes: buyerProfileViewModel.otherInformation.value.participantCodes,
        deliveryAddresses: cleanAddressesErrors(buyerProfileViewModel.otherInformation.value.deliveryAddresses),
        termsAndConditions: buyerProfileViewModel.otherInformation.value.termsAndConditions,
        marketingOptions: getMarketingOptionValue(buyerProfileViewModel.otherInformation.value.marketingOptions),
        legalEntityName: buyerProfileViewModel.accountInformation.value.legalEntityName,
        tradingName: buyerProfileViewModel.accountInformation.value.tradingName,
        agencies: buyerProfileViewModel.accountInformation.value.agencies.map(agency => {
            return {
                id: agency.id,
                agencyId: parseInt(agency.agencyId as string),
                accountName: agency.accountName,
                accountNumber: agency.accountNumber,
            }
        }),
    } as BuyerProfileUpdateMutationVariables
}
