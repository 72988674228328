<script lang="ts">
    export let value: boolean = false
    export let required: boolean = false
    export let isError: boolean = false
    export let id: string = ''

    $: isError = required && !value
</script>

<input {id} type="checkbox" class={required && !value ? 'error' : ''} on:click={() => (value = !value)} checked={value} />

<style lang="scss">
    input[type='checkbox'] {
        margin-left: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;

        margin: 0;
        background-color: #fff;
        opacity: 1;
        position: relative;
        border: 1px solid grey;
        pointer-events: all !important;
        -moz-appearance: checkbox;

        &.error {
            border: 1px solid var(--color-error);
            -moz-appearance: none;
            -webkit-appearance: none;
            -o-appearance: none;

            &:focus {
                box-shadow: 0 0 1px 0.2rem var(--color-error);
            }
        }
    }
</style>
