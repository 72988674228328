import { v4 as uuidv4 } from 'uuid'

export const signupFormInitValues: SignupFormType = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    mobileNumber: '',
    landLine: '',
    address1: '',
    address2: '',
    city: '',
    postCode: '',
    country: 'New Zealand',
    termsAndConditions: false,
    marketingOptions: {
        email: false,
        sms: false,
    },
    legalEntityName: '',
    tradingName: '',
    agencies: [{ rowId: uuidv4(), id: 0, agencyId: '', accountNumber: '', accountName: '' } as AgencyAccount],
    naitNumbers: [],
    herdCodes: [],
    participantCodes: [],
    deliveryAddresses: [],
}

export const signupFormMaxLength: SignupMaxLengthType = {
    firstName: 30,
    lastName: 30,
    emailAddress: 255,
    mobileNumber: 100,
    landLine: 100,
    address1: 100,
    address2: 100,
    city: 100,
    postCode: 4,
    country: 30,
    tradingName: 100,
    naitNumber: 8,
    herdCode: 100,
    participantCode: 100,
    agencies: {
        accountNumber: 50,
        accountName: 100,
    },
    addresses: {
        address1: 100,
        address2: 100,
        city: 100,
        postalCode: 4,
    },
}

export const auctionAccount: AuctionAccount = {
    address1: 100,
    address2: 100,
    city: 100,
    post: 4,
    naitNumber: 100,
    herdCode: 100,
    participantCode: 100,
}
