export const auctionInsuranceOptions: Option[] = [
    {
        value: 'BWRS',
        label: "BrokerWeb Risk Services to contact me - bidr's Preferred Supplier",
    },
    {
        value: 'Other',
        label: 'I will arrange my own insurance',
    },
    {
        value: 'Decline',
        label: 'I do not require insurance',
    },
]

export const legalEntityOptions: Option[] = [
    { label: 'Sole Trader', value: 'Sole Trader' },
    { label: 'Partnership', value: 'Partnership' },
    { label: 'Company', value: 'Company' },
    { label: 'Limited Partnership', value: 'Limited Partnership' },
    { label: 'Trust', value: 'Trust' },
]
